// ----------------------------------------------------------------------

import { AuthCallback } from 'casdoor-react-sdk';
import Sdk from 'casdoor-js-sdk';
import { useAuthContext } from 'src/auth/hooks';
import { HOST_API, PATH_AFTER_LOGIN } from 'src/config-global';
import { useSearchParams, useRouter } from '../../../routes/hooks';
import { setSession } from './utils';
import { STORAGE_KEY } from './auth-provider';

export default function CallbackPage() {
  const { login } = useAuthContext();
  console.info('call/back/form');
  const router = useRouter();
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const sdkConfig = {
    serverUrl: 'http://www.echo-bio.com:8002',
    clientId: 'b3271fb11c5eb7a3dbcc',
    appName: 'echo-bio-frontend',
    organizationName: 'echo-bio',
    redirectPath: '/callback',
  };

  const CasdoorSDK = new Sdk(sdkConfig);

  if (!sessionStorage.getItem(STORAGE_KEY)) {
    return (
      <AuthCallback
        sdk={CasdoorSDK}
        serverUrl={HOST_API || ''}
        saveTokenFromResponse={(res: any) => {
          console.log('>>>>>>>>>>>>>> add token to sessionStorage ');
          setSession(res.data.access_token);

          login();
          router.push(returnTo || PATH_AFTER_LOGIN);
        }}
        isGetTokenSuccessful={(res) => true}
      />
    );
  }
  console.log("already_login")
}
