// routes
import { paths } from 'src/routes/paths';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: '首页',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  // {
  //   title: 'Components',
  //   icon: <Iconify icon="solar:atom-bold-duotone" />,
  //   path: paths.components,
  // },
  {
    title: '关于我们',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.about,
  },
  {
    title: '联系我们',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.contact,
  }
];
